<template lang="html">
  <div class="component-wrapper register-wrapper">
    <Suggestions></Suggestions>
  </div>
</template>

<script>
import Suggestions from '@/components/suggestions/Suggestions.vue'
export default {
    components: {
        Suggestions
    },
    mounted() {

    }
}
</script>

<style lang="css" scoped>
.register-wrapper {
}
</style>
