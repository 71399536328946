<template lang="html">
    <div class="component-wrapper">
        <div class="d-flex justify-content-center suggestion-wrapper" v-if="this.$store.state.isAuthenticated">
            <div class="suggest-div">
                <div v-if="!this.showFinished">
                    <h1 class="mb-3">Know of a podcast that should be on PodioDramas but isn't?</h1>
                    <p>Fill out this form and let us know!</p>
                    <b-form method="post">
                        <b-input v-model="name" class="mb-2 suggestion-input"
                                 placeholder="Podcast name"
                                 type="text"
                                 minlength="1">
                        </b-input>
                        <b-input v-model="link" class="mb-2 suggestion-input"
                                 placeholder="Link (podcast's website, Apple Podcasts, whatever...)"
                                 type="text"
                                 minlength="1">
                        </b-input>
                        <b-input v-model="comments" class="mb-2 suggestion-input"
                                 placeholder="Any comments?"
                                 type="text">
                        </b-input>
                        <div class="d-flex">
                            <button v-bind:class="validForm ? 'none': 'disabled-click'" id="login-button" @click.prevent="sendSuggestion">
                                <b>Send suggestion</b>
                            </button>
                            <div class="loading-div suggestion">
                                <div v-if="this.showLoading" class="spinner-border" role="status">
                                    <!-- <span class="sr-only">Loading...</span> -->
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
                <div v-if="this.showFinished">
                    <p>Suggestion received! Thank you for contributing to PodioDramas.</p>
                    <button class="mt-3 another-suggestion-button"
                            @click.prevent="anotherSuggestion"
                            name="button">
                        <p>Another suggestion</p>
                    </button>
                </div>
            </div>
        </div>
        <div v-else>
            <h5 class="must-be-logged-in">You must be logged in to suggest a podcast</h5>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Suggestion',
    data() {
        return {
            name: '',
            link: '',
            comments: '',
            showLoginError: false,
            showLoading: false,
            showFinished: false
        }
    },
    computed: {
        validForm() {
            return this.name.length > 0 && this.link.length > 0
        }
    },
    methods: {
        anotherSuggestion() {
            this.name = ''
            this.link = ''
            this.comments = ''
            this.showFinished = !this.showFinished
        },
        sendSuggestion() {
            this.showLoading = true
            const base = {
                baseURL: this.$store.state.endpoints.baseUrl,
                headers: {
                    Authorization: `JWT ${this.$store.state.jwt}`,
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true,
                },
            }
            const axiosInstance = this.$http.create(base);
            axiosInstance({
                url: `suggestions/`,
                method: 'post',
                data: {'name': this.name, 'link': this.link, 'comments': this.comments},
            }).then(() => {
                this.showLoading = false
                this.showFinished = true
            })
        }
    }
}
</script>

<style lang="css" scoped>
    .suggestion-wrapper {
        height: 100%;
    }
    .suggest-div {
        padding: 2em;
        border-radius: 1em;
        width: 100%;
        box-shadow: 3px 3px 3px 5px var(--darkBackground);
        color: white;
    }
    #login-button {
        background: white;
        box-shadow: 3px 3px 3px var(--darkBackground),
                            -1px -1px 3px var(--background);
        border-radius: 1em;
        width: 8em;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--darkBackground);
        align-self: center;
        padding: 0.5em;
        border: none;
    }
    .login-error {
        color: red;
        font-weight: bold;
    }
    .suggestion-input {
        width: 33%;
    }
    @media (max-width: 792px) {
        .suggestion-input {
            width: 100%;
        }
    }
    .loading-div.suggestion {
        margin-left: 1em;
        display: flex;
        align-items: center;
    }
    .another-suggestion-button {
        background: white;
        box-shadow: 3px 3px 3px var(--darkBackground),
                            -1px -1px 3px var(--background);
        border-radius: 1em;
        width: 8em;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--darkBackground);
        align-self: center;
        padding: 0.5em;
        border: none;
        font-weight: bolder;
    }
    .must-be-logged-in {
        color: white;
    }
    .disabled-click {
        pointer-events:none;
    }
</style>
